import Section from "../../components/BrandComparisonSection";
import styles from "../../styles/pages/menstruationHabits/index.module.css";
import SemiChart from "../../components/SemiDouughnutChart";
import genderIcon from "../../assets/home/gender-icon.svg";
import chartIcon from "../../assets/home/chart-icon.svg";
import horizontalChartIcon from "../../assets/menstruationHabits/horizontalChartIcon.svg";
import HorizontalBarChart from "../../components/horizontalBarChart";
import SimpleBarChart from "../../components/BarChart";
const sleepDurationData = require("../../data/menstruationHabits/sleepDuration.json");
const sleepData = require("../../data/menstruationHabits/sleepData.json");
const waterIntakeData = require("../../data/menstruationHabits/waterIntakeData.json");

const MenstruationHabitsRowOne = () => {
  return (
    <>
      <Section
        width={33}
        heading={
          <div className={styles.heading}>
            <img src={genderIcon} alt="" />
            <p>
              Do you have trouble falling asleep right before or during your
              period?
            </p>
          </div>
        }
        content={
          sleepData.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "10px",
                marginTop: "20px",
              }}
            >
              <SemiChart data={sleepData} label="Women 99%" />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "30px",
                }}
              >
                {sleepData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "16px",
                        width: "16px",
                        borderRadius: "50%",
                        backgroundColor: `${item.fill}`,
                      }}
                    ></div>
                    <div>{item.title || item.name}</div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>no data found</div>
          )
        }
      />

      <Section
        width={33}
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>
              How many hours of sleep do you get on average right before or
              during your period?
            </p>
          </div>
        }
        content={
          sleepDurationData.length > 0 ? (
            <SimpleBarChart data={sleepDurationData} />
          ) : (
            <div>No Data Found</div>
          )
        }
      />

      <Section
        width={33}
        heading={
          <div className={styles.heading}>
            <img src={horizontalChartIcon} alt="" />
            <p>
              On average, how much water do you drink when you are on your
              period?
            </p>
          </div>
        }
        content={
          waterIntakeData.length > 0 ? (
            <HorizontalBarChart data={waterIntakeData} />
          ) : (
            <div>No Data Found</div>
          )
        }
      />
    </>
  );
};

export default MenstruationHabitsRowOne;
