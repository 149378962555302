import CompareBarChart from "../../components/CompareBarChart";
import chartIcon from "../../assets/demographics/chart-icon.svg";
import chartTilted from "../../assets/demographics/chart-tilted-icon.svg";
import Section from "../../components/BrandComparisonSection";
import ComparisonStackedChart from "../../components/StackedBarChart";
import styles from "../../styles/pages/demographics/MobileComponent.module.css";
import LineCompareChart from "../../components/CompareLineChart";
import BarAndLine from "../../components/barAndLineChart";
import shuffleArray from "../../utils/shuffle";
const ageData = require("../../data/demographics/age.json");
const skinSensitiveData = require("../../data/demographics/skinSensitivityData.json");

const ComparisonMobile = () => {
  return (
    <div className={`${styles.mobileContentContainer} hide-in__desktop`}>
      <Section
        heading={
          <div style={{ display: "flex", gap: "12px" }}>
            <img src={chartIcon} alt="" />
            <p>Top Skin Types</p>
          </div>
        }
        content={
          <BarAndLine
            data={skinSensitiveData}
            yAxisWidth={1}
            tooltipTrigger="click"
          />
        }
        width={100}
      />

      <Section
        heading={
          <div style={{ display: "flex", gap: "12px" }}>
            <img src={chartIcon} alt="" />
            <p>Age</p>
          </div>
        }
        content={
          <LineCompareChart
            data={shuffleArray(ageData)}
            yAxisWidth={10}
            tooltipTrigger="click"
          />
        }
        width={100}
      />
    </div>
  );
};

export default ComparisonMobile;
