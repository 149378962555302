import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import newLogo from "../assets/home/unicharmLogo.svg";
import menstruationHabits from "../assets/home/menstruationHabitsIcon.svg";
import menstruation from "../assets/home/menstruationLogo.svg";
import demographicsLogo from "../assets/home/age-icon.svg";
import styles from "../styles/components/common/Sidebar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Sidebar = ({ handleChange, currentPage }) => {
  const { SubMenu } = Menu;
  const location = useLocation();

  const getItem = (label, key, svgComponent, children, type) => {
    return {
      key,
      svgComponent,
      children,
      label,
      type,
    };
  };

  const items = [
    getItem(
      "Demographics",
      "1",
      <img alt="" height={28} src={demographicsLogo} />
    ),
    getItem(
      "Menstruation Habits",
      "2",
      <img alt="" height={28} src={menstruationHabits} />
    ),
    getItem("Menstruation", "3", <img alt="" height={28} src={menstruation} />),
  ];

  const [selectedKey, setSelectedKey] = useState(null);
  const [active, setActive] = useState(null);
  const navigate = useNavigate();

  const handleClick = (e) => {
    // Handle click event for menu items
    handleChange(e);
    setSelectedKey(e);

    switch (e) {
      case "1":
        navigate("/");
        setActive(1);
        break;
      case "2":
        navigate("/menstruation-habits");
        setActive(2);
        break;
      case "3":
        navigate("/menstruation");
        setActive(3);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Set active based on current pathname
    const pathname = location.pathname;
    switch (pathname) {
      case "/":
        setActive(1);
        setSelectedKey("1");
        break;
      case "/menstruation-habits":
        setActive(2);
        setSelectedKey("2");
        break;
      case "/menstruation":
        setActive(3);
        setSelectedKey("3");
        break;
      default:
        break;
    }
  }, [location.pathname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFF",
        alignItems: "start",
        position: "fixed",
        zIndex: 3,
        top: 0,
        padding: "24px 0px 0px 0px",
        width: "280px",
        height: "100vh",
        fontFamily: "Urbanist",
      }}
      className="hide-in__mobile"
    >
      <img
        alt=""
        height={45}
        style={{ marginLeft: "25px", marginBottom: "20px" }}
        src={newLogo}
        onClick={() => handleClick("1")}
      />
      <Menu
        mode="inline"
        style={{ width: 256 }}
        className={styles.sidebarMenu}
        selectedKeys={[selectedKey]}
      >
        {items.map((item) => {
          const isSelected = selectedKey === item.key;

          if (item.children) {
            return (
              <SubMenu
                key={item.key}
                title={
                  <div className={styles.menuItemTitle}>
                    {item.svgComponent && (
                      <span className={styles.menuItemIcon}>
                        {item.svgComponent}
                      </span>
                    )}
                    <span
                      className={`${styles.menuItemTitleBold} ${
                        isSelected ? styles.selectedMenuItem : ""
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>
                }
                popupClassName={styles.subMenu}
                className={isSelected ? styles.selectedMenuItem : ""}
              >
                {item.children.map((childItem) => (
                  <Menu.Item
                    key={childItem.key}
                    onClick={() => handleClick(childItem.key)}
                    className={isSelected ? styles.selectedMenuItem : ""}
                  >
                    <div className={styles.menuItemTitle}>
                      <span className={styles.menuItemIcon}>
                        {childItem.svgComponent}
                      </span>
                      <span
                        className={`${styles.menuItemTitleBold} ${
                          isSelected ? styles.selectedMenuItem : ""
                        }`}
                      >
                        {childItem.label}
                      </span>
                    </div>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item
                key={item.key}
                onClick={() => handleClick(item.key)}
                className={`${styles.menuItem} ${
                  isSelected ? styles.selectedMenuItem : ""
                }`}
              >
                <div className={styles.menuItemTitle}>
                  {item.svgComponent && (
                    <span className={styles.menuItemIcon}>
                      {item.svgComponent}
                    </span>
                  )}
                  <span
                    className={`${styles.menuItemTitleBold} ${
                      isSelected ? styles.selectedMenuItem : ""
                    }`}
                  >
                    {item.label}
                  </span>
                </div>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </div>
  );
};

export default Sidebar;
