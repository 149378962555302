import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from "recharts";

const CustomLegend = (props) => {
  const { payload } = props;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      {payload.map((entry, index) => (
        <div
          key={`legend-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 8,
            border: "0.8px solid lightgrey",
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: entry.color,
              borderRadius: "50%",
              marginRight: 5,
            }}
          />
          <span style={{ fontSize: 14, marginRight: 5 }}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const BarAndLine = ({ data, yAxisWidth, tooltipTrigger }) => {
  const customXAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, 9); // Adjust the truncation length as needed

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject
          x={-25}
          y={0}
          dy={16}
          fontSize={10}
          fill="#666"
          width={100}
          height={30}
          textAnchor="end"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{ fontSize: "11px" }}
            dangerouslySetInnerHTML={{ __html: truncatedLabel }}
          />
        </foreignObject>
      </g>
    );
  };

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, 8); // Adjust the truncation length as needed

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} fontSize={10} textAnchor="end" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      let truncatedLabel = "";
      for (let i = 0; i < label.length; i += maxLength) {
        truncatedLabel += label.substring(i, i + maxLength) + "<br />";
      }
      return truncatedLabel;
    }
    return label;
  };

  return (
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 10,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="key"
          tick={customXAxisTick}
          interval={0}
          // marginRight={"10px"}
          // fontSize={"6px"}
        />
        <YAxis width={yAxisWidth ? yAxisWidth : 0} tick={CustomYAxisTick} />
        <Tooltip trigger={tooltipTrigger ? tooltipTrigger : "hover"} />
        <Legend content={<CustomLegend />} />
        <Bar dataKey="Never" barSize={25} fill="#18181B" />
        <Line
          type="straight"
          dataKey="Visiting"
          stroke="#3F4E99"
          strokeWidth={2}
          dot={{ fill: "#3F4E99", strokeWidth: 2, r: 4 }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default BarAndLine;
