import { useState } from "react";
import genderIcon from "../../assets/home/gender-icon.svg";
import Section from "../../components/BrandComparisonSection";
import MobileNav from "../../components/MobileNav";
import SemiChart from "../../components/SemiDouughnutChart";
import Sidebar from "../../components/Sidebar";
import styles from "../../styles/pages/menstruation/index.module.css";
import chartIcon from "../../assets/home/chart-icon.svg";
import SimpleBarChart from "../../components/BarChart";

const productPreferenceData = require("../../data/menstruation/productPreferenceData.json");
const periodFlowData = require("../../data/menstruation/periodFlowData.json");
const periodCrampsData = require("../../data/menstruation/periodCrampsData.json");

const Menstruation = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };
  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <MobileNav />
      <div className={styles.bodyContainer}>
        <div className={styles.subBodyContainer}>
          {page === 1 && (
            <div className="hide-in__mobile">
              <div className={styles.topBar}>
                <h2>Menstruation</h2>
              </div>
              <div className={styles.brandsSelectContainer}>
                <Section
                  width={100}
                  heading={
                    <div className={styles.heading}>
                      <img src={genderIcon} alt="" />
                      <p>
                        Do you prefer reusable or disposable period products?
                      </p>
                    </div>
                  }
                  content={
                    productPreferenceData.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "60px",
                          paddingBottom: "10px",
                          marginTop: "60px",
                        }}
                      >
                        <SemiChart
                          data={productPreferenceData}
                          label="Women 99%"
                        />
                        <div
                          style={{
                            marginTop: "10px",
                            marginLeft: "60px",
                            display: "flex",
                            width: "70%",
                            flexDirection: "column",
                            gap: "30px",
                          }}
                        >
                          {productPreferenceData.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                border: "1px solid #F2F2F2",
                                borderRadius: "8px",
                                padding: "12px",
                              }}
                            >
                              <div
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  borderRadius: "50%",
                                  backgroundColor: `${item.fill}`,
                                }}
                              ></div>
                              <div>{item.title || item.name}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div>no data found</div>
                    )
                  }
                />

                <Section
                  width={100}
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>How heavy is your period flow?</p>
                    </div>
                  }
                  content={
                    periodFlowData.length > 0 ? (
                      <SimpleBarChart data={periodFlowData} />
                    ) : (
                      <div>No Data Found</div>
                    )
                  }
                />

                <Section
                  width={100}
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>Do you experience cramps on your period?</p>
                    </div>
                  }
                  content={
                    periodCrampsData.length > 0 ? (
                      <SimpleBarChart data={periodCrampsData} />
                    ) : (
                      <div>No Data Found</div>
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menstruation;
