import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./components/404";
import MenstruationHabits from "./pages/menstruationHabits";
import Menstruation from "./pages/menstruation";
import Demographics from "./pages/demographics";

const App = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Demographics />} />
        <Route path="/menstruation-habits" element={<MenstruationHabits />} />
        <Route path="/menstruation" element={<Menstruation />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
