import { useState } from "react";
import styles from "../../styles/pages/demographics/index.module.css";
import chartIcon from "../../assets/demographics/chart-icon.svg";
import ageIcon from "../../assets/home/age-icon.svg";
import skinTypesicon from "../../assets/home/skintypes-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import MobileNav from "../../components/MobileNav";
import Sidebar from "../../components/Sidebar";
import CustomDonutChart from "../../components/doughnutChart";
import ComparisonMobile from "./MobileComponent";

const AgeData = require("../../data/demographics/age.json");
const skinSensitiveData = require("../../data/demographics/skinSensitivityData.json");
const skinData = require("../../data/menstruationHabits/skinTypes.json");
const cityData = require("../../data/demographics/cityData.json");
const skinConcerns = require("../../data/demographics/skinConcern.json");

const BeautyParlours = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };
  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <MobileNav />
      <div className={styles.bodyContainer}>
        <ComparisonMobile />
        <div className={styles.subBodyContainer}>
          {page === 1 && (
            <div className="hide-in__mobile">
              <div className={styles.topBar}>
                <h2>Demographics</h2>
              </div>
              <div className={styles.brandsSelectContainer}>
                <Section
                  width={33}
                  heading={
                    <div className={styles.heading}>
                      <img src={ageIcon} alt="" />
                      <p>Age</p>
                    </div>
                  }
                  content={
                    AgeData.length > 0 ? (
                      <SimpleBarChart data={AgeData} />
                    ) : (
                      <div>No Data Found</div>
                    )
                  }
                />

                <Section
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>
                        Do you feel your skin is more sensitive during your
                        period?
                      </p>
                    </div>
                  }
                  content={
                    <CompareBarChart
                      data={skinSensitiveData}
                      yAxisWidth={20}
                      showLegend={false}
                    />
                  }
                  width={36}
                />

                <Section
                  width={31}
                  heading={
                    <div className={styles.heading}>
                      <img src={skinTypesicon} alt="" />
                      <p>Skin Type</p>
                    </div>
                  }
                  content={
                    skinData.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div style={{ width: "65%", marginTop: "40px" }}>
                          <CustomDonutChart propData={skinData} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // flexWrap: "wrap",
                            gap: "10px",
                            width: "60%",
                            marginTop: "30px",
                          }}
                        >
                          {skinData.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                height: "50px",
                                width: "80%",
                                border: "1px solid #F2F2F2",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                padding: "6px",
                                borderRadius: "8px",
                              }}
                            >
                              <div
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  borderRadius: "50%",
                                  backgroundColor: `${item.fill}`,
                                  gap: "10px",
                                }}
                              ></div>
                              <div>{item.title || item.name}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div>no data</div>
                    )
                  }
                />
              </div>
              <div className={styles.brandsSelectContainer}>
                <Section
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>Top City</p>
                    </div>
                  }
                  content={
                    <CompareBarChart
                      data={cityData}
                      yAxisWidth={20}
                      showLegend={false}
                      slantXAxisLabels={true}
                    />
                  }
                  width={50}
                />

                <Section
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>What are your skin concerns?</p>
                    </div>
                  }
                  content={
                    <CompareBarChart
                      data={skinConcerns}
                      yAxisWidth={20}
                      showLegend={false}
                      slantXAxisLabels={true}
                    />
                  }
                  width={50}
                />
              </div>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BeautyParlours;
