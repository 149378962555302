import { useState } from "react";
import styles from "../../styles/pages/menstruationHabits/index.module.css";
import Sidebar from "../../components/Sidebar";
import MenstruationHabitsRowTwo from "./MenstruationHabitsRowTwo";
import MenstruationHabitsRowOne from "./MenstruationHabitsRowOne";

const Sunscreen = () => {
  const [index, setIndex] = useState(0);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };
  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <div className={styles.bodyContainer}>
        <div className={styles.subBodyContainer}>
          <div className={styles.topBar}>
            <h2>Menstruation Habits</h2>
          </div>

          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            <MenstruationHabitsRowOne />
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
            <MenstruationHabitsRowTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sunscreen;
