import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from "recharts";

const CustomLegend = (props) => {
  const { payload } = props;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      {payload.map((entry, index) => (
        <div
          key={`legend-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 8,
            border: "0.8px solid lightgrey",
            padding: "2px 8px",
            borderRadius: "4px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: entry.color,
              borderRadius: "50%",
              marginRight: 5,
            }}
          />
          <span style={{ fontSize: 14, marginRight: 5 }}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const CompareBarChart = ({
  data,
  chartWidthPercentage,
  barWidth,
  yAxisWidth,
  tooltipTrigger,
  showLegend = true,
  slantXAxisLabels = false,
}) => {
  // Extract brands dynamically from the first data entry
  const customXAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, 10);

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject
          x={-30}
          y={0}
          // dy={slantXAxisLabels}
          dy={slantXAxisLabels ? 16 : 24}
          width={80}
          height={60}
          marginBottom={30}
          textAnchor={"end"}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: "10px",
              color: "#71717A",
              transform: slantXAxisLabels ? "rotate(-45deg)" : "rotate(0deg)",
            }}
            dangerouslySetInnerHTML={{ __html: truncatedLabel }}
          />
        </foreignObject>
      </g>
    );
  };

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, 5);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} fontSize={10} textAnchor="end" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "..";
    }
    return label;
  };

  const brands =
    data.length > 0 ? Object.keys(data[0][Object.keys(data[0])[0]]) : [];

  return (
    <ResponsiveContainer width={chartWidthPercentage} height={300}>
      <BarChart
        data={data}
        padding={10}
        margin={{
          top: 10,
          right: 10,
          bottom: 35,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey={(city) => Object.keys(city)[0]}
          tick={customXAxisTick}
          interval={0}
          height={30}
        />
        <YAxis
          width={yAxisWidth ? yAxisWidth : 0}
          tick={CustomYAxisTick}
          interval={0}
        />
        <Tooltip trigger={tooltipTrigger ? tooltipTrigger : "hover"} />
        {showLegend && <Legend content={<CustomLegend />} />}{" "}
        {brands.map((brand, index) => (
          <Bar
            key={index}
            dataKey={(city) => city[Object.keys(city)[0]][brand]}
            // fill={brand === "Never" ? "#FACC15" : "#18181B"}
            fill={brand === "Never" ? "#18181B" : "#3F4E99"}
            name={brand}
            barSize={barWidth || 14}
            radius={[100, 100, 100, 100]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CompareBarChart;
