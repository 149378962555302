import CompareBarChart from "../../components/CompareBarChart";
import Section from "../../components/BrandComparisonSection";
import styles from "../../styles/pages/menstruationHabits/index.module.css";

import chartIcon from "../../assets/home/chart-icon.svg";
import SimpleBarChart from "../../components/BarChart";
const stressData = require("../../data/menstruationHabits/stressData.json");
const periodFoods = require("../../data/menstruationHabits/periodFoods.json");
const ExData = require("../../data/menstruationHabits/exerciseData.json");

const MenstruationHabitsRowTwo = () => {
  return (
    <>
      <Section
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>
              Which of the following foods do you tend to eat when you are on
              your period?
            </p>
          </div>
        }
        content={
          <CompareBarChart
            data={periodFoods}
            yAxisWidth={20}
            showLegend={false}
            barColor="#27272A"
          />
        }
        width={40}
      />

      <Section
        width={30}
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>How often do you exercise when you are on your period?</p>
          </div>
        }
        content={
          ExData.length > 0 ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {ExData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    height: "55px",
                    backgroundColor: `rgba(252, 236, 0, ${item.value / 5})`,
                    borderRadius: "8px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 16px 0px 10px",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>
                    {item.title || item.name}
                  </p>
                  <p
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#FFF",
                      padding: "5px",
                      border: "1px solid #3F4E99",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    0{item.value}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div>No data found</div>
          )
        }
      />

      <Section
        width={30}
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>Do you feel more stressed right before or during your period?</p>
          </div>
        }
        content={
          stressData.length > 0 ? (
            <SimpleBarChart data={stressData} />
          ) : (
            <div>No Data Found</div>
          )
        }
      />
    </>
  );
};

export default MenstruationHabitsRowTwo;
