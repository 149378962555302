const Section = ({ width, heading, content, height }) => {
  const sectionStyle = {
    width: `${width ? width : 30}%`,
    height: `${height ? height : 335}px`,
    // height: "360px",
    backgroundColor: "#fff",
    padding: "0px 0px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    // boxShadow: "0px 4px 5px 0px #0000000F",
    border: "1px solid #E4E4E7",
    transition: "transform 0.3s ease", // Add transition property

    ":hover": {
      transform: "scale(0.96)", // Zoom effect on hover
    },
  };
  return (
    <div style={sectionStyle}>
      {heading}
      {content}
    </div>
  );
};

export default Section;
