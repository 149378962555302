// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileComponent_mobileContentContainer__OeEM2 {
  background-color: #fff;
  padding: 12px 12px 2px 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/demographics/MobileComponent.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;AACrB","sourcesContent":[".mobileContentContainer {\n  background-color: #fff;\n  padding: 12px 12px 2px 12px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileContentContainer": `MobileComponent_mobileContentContainer__OeEM2`
};
export default ___CSS_LOADER_EXPORT___;
